import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'kebab'
})
export class KebabPipe implements PipeTransform {

  transform(value: string): string {
    return value
      .replace(/([a-z])([A-Z])/g, '$1-$2') // Replace capital letters with a hyphen and the letter
      .replace(/([A-Z])([A-Z][a-z])/g, '$1-$2') // Handle consecutive capital letters
      .toLowerCase(); // Convert the final result to lower case
  }
}
