import { Component } from '@angular/core';

import { MobileAccessibility } from '@ionic-native/mobile-accessibility';
import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { Router, RouterOutlet } from '@angular/router';

import { slider } from './transitions/route-animations';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  animations: [
    slider
  ]
})
export class AppComponent {

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private router: Router,
  ) {
    this.initializeApp();
      if(!localStorage.getItem('didReadDisclaimer')) {
        this.router.navigateByUrl('/disclaimer');
      }
  }

  initializeApp() {
    this.platform.ready().then(() => {
      if(this.platform.is('android')) {
        this.statusBar.styleLightContent();
      } else {
        this.statusBar.styleDefault();
      }
      this.splashScreen.hide();
    });

    // @ts-ignore
    MobileAccessibility.getTextZoom((zoom) => {
      if(zoom >= 135) {
        MobileAccessibility.setTextZoom(135);
        if(this.platform.is('android')) {
          const sheet = window.document.styleSheets[0];
          // @ts-ignore
          sheet.insertRule('ion-tab-bar { zoom: 1.5; }', sheet.cssRules.length);
        }
      }
      // document.getElementsByTagName("body")[0].style.fontSize = '1.2em';
      // alert(zoom);
    });
  }

  prepareOutlet(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }
}
