import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';


const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' }, 
  {
    path: 'home',
    loadChildren: './home/home.module#HomePageModule',
    // data: { animation: 'isLeft'}
  },
  {
    path: 'tabs',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule),
    // data: { animation: 'isRight' }
  },
  {
    path: 'language',
    loadChildren: () => import('./language/language.module').then( m => m.LanguagePageModule),
    // data: { animation: 'isRight' }
  },
  {
    path: 'introduction',
    loadChildren: () => import('./home/introduction/introduction.module').then( m => m.IntroductionPageModule),
    // data: { animation: ''}
  },
  {
    path: 'terms-and-condition',
    loadChildren: () => import('./home/terms-and-conditions/terms-and-conditions.module').then( m => m.TermsAndConditionsPageModule)
  },
  {
    path: 'disclaimer',
    loadChildren: () => import('./disclaimer/disclaimer.module').then( m => m.DisclaimerPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
